// About.jsx
import React, { forwardRef } from "react";
import "./About.css";
import DetailCards from "./DetailCards";

const About = forwardRef((props, ref) => {
  const handleSeeMoreClick = () => {
    if (props.scrollToCatalog) {
      props.scrollToCatalog();
    }
  };

  return (
    <div className="section" id="about" ref={ref}>
      <div className="about-section-container">
        <div className="about-gradient-background">
          {/* Apply gradient background here */}
        </div>
        <h2 className="section-header">Services We Provide</h2>
        <div className="about-section-details-container">
          <p className="about-section-details">
          At Zenith Technologies, we embark on a transformative journey through cutting-edge software solutions, redefining your organization's technological landscape. Our mission is to foster seamless connections within your organization, enriching interactions, and optimizing your technological endeavors
          </p>
        </div>
        <div className="detail-card-container">
          <DetailCards />
        </div>
        {/* Attach onClick event handler to the button */}
        <div className="about-section-button" onClick={handleSeeMoreClick}>
          See More About Our Products
        </div>
      </div>
    </div>
  );
});

export default About;
