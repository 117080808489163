import React, { useEffect, useState } from "react";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
  faX,
} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return  (
    <div className="section" id="contact">
      {/* <div className="test-contact">

      </div> */}
      <div className="contact-container">
        <div className="header-buffer"></div>
        <h2 className="contact-section-header">Contact Us</h2>
        <p className="contact-section-details">
          Interesting in joining us at Zenith Technologies and developing a
          software solution that can benefit your organization?
        </p>

        <div className="contact-form">
          <div className="contact-information">
            <h2 className="contact-information-title">Contact Information</h2>
            <p className="contact-information-subtext">
              Fill out this form and our team will get back to you right away
            </p>
            <div className="contact-information-data">
              <div className="contact-information-data-row-item">
                <FontAwesomeIcon icon={faPhone} />
                <p className="contact-information-data-row-item-text">
                  803-313-7175
                </p>
              </div>
              <div className="contact-information-data-row-item">
                <FontAwesomeIcon icon={faEnvelope} />
                <p className="contact-information-data-row-item-text">
                  contactme@colbyjones.tech
                </p>
              </div>
              <div className="contact-information-data-row-item">
                <FontAwesomeIcon icon={faLocationDot} />
                <p className="contact-information-data-row-item-text">
                  South Carolina
                </p>
              </div>
            </div>
          </div>
          <div className="contact-data-fields">
            <form>
              <div className="name-fields">
                <div className="input-and-text-container">
                  <p className="field-text">First Name</p>
                  <input type="text" placeholder="First Name" />
                </div>
                <div className="input-and-text-container">
                  <p className="field-text">Last Name</p>
                  <input type="text" placeholder="Last Name" />
                </div>
              </div>

              <div className="contact-data-field-info">
                <div className="input-and-text-container">
                  <p className="field-text">Mail</p>
                  <input type="email" placeholder="Your Email" />
                </div>
                <div className="input-and-text-container">
                  <p className="field-text">Phone</p>
                  <input type="phone" placeholder="Your Contact Number" />
                </div>
              </div>

              <div className="message-container">
                <textarea
                  className="contact-text-area"
                  placeholder="Your Message"
                ></textarea>
              </div>
              <div className="button-container">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
