const detailCardsData = [
  {
    image: "image1.jpg",
    header: "Database",
    details:
      "We specialize in crafting, safeguarding, and optimizing your company's data infrastructure.",
  },
  {
    image: "image2.jpg",
    header: "Optimization",
    details:
      "Our software solutions are meticulously tailored to unleash peak performance across all platforms, be it web, mobile, or cloud-based environments.",
  },
  {
    image: "image3.jpg",
    header: "Custom Design",
    details:
      "Every aspect of the development process is meticulously crafted with your unique requirements at the forefront.",
  },
  {
    image: "image4.jpg",
    header: "Trends",
    details:
      "We are committed to ensuring that your software solutions stay current with the latest formats and trends, keeping your business at the forefront of innovation.",
  },
];

export default detailCardsData;
