// Catalog.jsx
import React, { forwardRef } from "react";
import "./Catalog.css";
import CatalogCard from "./CatalogCard";

const Catalog = forwardRef((props, ref) => {
  return (
    <div className="section" id="catalog" ref={ref}>
      <div className="catalog-section-container">
        <div className="header-buffer"></div>
        <div>
          <h2 className="catalog-section-header">Features</h2>
        </div>
        <div className="catalog-section-details-container">
          <p className="catalog-section-details">
            Explore our catalog to uncover the array of offerings awaiting you
            when you engage with Zenith Technologies.
          </p>
        </div>
        <CatalogCard />
      </div>
    </div>
  );
});

export default Catalog;

