import React from "react";
import "./CatalogCard.css";
import logos from "../assets/logos.png";
import salkcal from "../assets/salk-calendar.png";
import salkmenu from "../assets/salk-menu.png";
import security from "../assets/data-security.png";
import integration from "../assets/tech-integration.png";

const CatalogCard = () => {
  // Sample data for demonstration
  const catalogItems = [
    {
      image: security,
      text: "Security is paramount in today's digital landscape. Our mobile apps are fortified with advanced security protocols to safeguard sensitive data and protect against potential threats. From encrypted communications to secure authentication methods, rest assured that your organization's information remains safe and confidential",
    },
    {
      image: integration,
      text: "Enhance operational efficiency by seamlessly integrating our mobile apps with existing systems and workflows. Our solutions are designed to harmonize with your organization's infrastructure, enabling smooth data exchange and interoperability across platforms. Enjoy a cohesive ecosystem where information flows effortlessly and processes are streamlined",
    },
    {
      image: salkcal,
      text: "Whether you're a small startup or a large enterprise, our mobile apps offer scalability and customization options to meet your evolving needs. From flexible deployment models to tailored features and functionalities, empower your organization with solutions that adapt and grow alongside your business objectives.",
    },
    {
      image: salkmenu,
      text: "Our mobile apps boast user-friendly interfaces designed to streamline navigation and enhance user experience. With intuitive layouts and clear design elements, users can quickly grasp functionality and maximize productivity",
    },
    {
      image: logos,
      text: "Accelerate your app development with our cross-platform efficiency, leveraging native platform functionality for rapid turnaround times. Our agile approach combines cross-platform frameworks with native development benefits, ensuring shorter cycles and faster releases. Benefit from rapid prototyping, simplified maintenance, and simultaneous deployment across platforms for a competitive edge in the market.",
    },
    // Add more catalog items as needed
  ];
  return (
    <div className="catalog-container">
      <div className="catalog-items">
        {catalogItems.map((item, index) => (
          <div
            key={index}
            className={`catalog-row ${index % 2 === 0 ? "even" : "odd"}`}
          >
            <div className="catalog-item-image">
              <img src={item.image} alt={`Image ${index + 1}`} />
            </div>
            <div className="catalog-item-text">
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CatalogCard;
