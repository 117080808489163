// App.jsx
import React, { useRef } from "react";
import "./App.css";
import Menu from "./components/Menu";
import About from "./components/About";
import Catalog from "./components/Catalog";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Products from "./components/Products";

function App() {
  const aboutRef = useRef(null);
  const catalogRef = useRef(null);

  const scrollToAbout = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToCatalog = () => {
    if (catalogRef.current) {
      catalogRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <Menu />
      </header>
      <div className="master-container">
        {/* Pass scrollToAbout function as a prop to Home component */}
        <Home scrollToAbout={scrollToAbout} />
        {/* Pass scrollToCatalog function as a prop to About component */}
        <About id="about" ref={aboutRef} scrollToCatalog={scrollToCatalog} />
        <Catalog id="catalog" ref={catalogRef} />
        {/* <Products id="products" /> */}
        <Contact id="contact" />
      </div>
    </div>
  );
}

export default App;
