// Menu.jsx
import React, { useEffect, useState } from "react";
import "./Menu.css";
import logo from "../assets/zentech-nobg.png";

const Menu = () => {
  const [isHeaderAtTop, setIsHeaderAtTop] = useState(true);
  const [showMenu, setShowMenu] = useState(false);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleItemClick = (id) => {
    scrollToSection(id);
    setShowMenu(false);
  };

  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top of the page
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsHeaderAtTop(scrollTop === 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const hasScrolledPastHome = () => {
    const homeSection = document.getElementById("home");
    if (homeSection) {
      return window.scrollY > homeSection.offsetHeight;
    }
    return false;
  };

  return (
    <div
      className={`header ${
        isHeaderAtTop && !hasScrolledPastHome() ? "header-at-top" : ""
      }`}
      style={{
        backgroundImage: hasScrolledPastHome()
          ? "linear-gradient(180deg, rgba(0,26,46,0.9), rgba(0,123,157,0.9))"
          : isHeaderAtTop
          ? "none"
          : "linear-gradient(180deg, rgba(0,26,46,0.9), rgba(0,123,157,0.9))",
      }}
    >
      <div className="logo-container" onClick={handleLogoClick}>
        <img
          src={logo}
          className={`comp-logo-image ${showMenu ? "shrink" : ""}`}
          alt="Zenith Technologies Logo"
        />
      </div>
      <div className={`menu-items ${showMenu ? "show" : ""}`}>
        <ul>
          <li>
            <a
              href="#about"
              onClick={(e) => {
                e.preventDefault();
                handleItemClick("about");
              }}
            >
              About
            </a>
          </li>
          <li>
            <a
              href="#catalog"
              onClick={(e) => {
                e.preventDefault();
                handleItemClick("catalog");
              }}
            >
              Features
            </a>
          </li>
          <li>
            <a
              href="#products"
              onClick={(e) => {
                e.preventDefault();
                handleItemClick("products");
              }}
            >
              Products
            </a>
          </li>
          <li>
            <a
              href="#contact"
              onClick={(e) => {
                e.preventDefault();
                handleItemClick("contact");
              }}
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
      <div
        className={`hamburger-menu ${showMenu ? "change" : ""}`}
        onClick={() => setShowMenu(!showMenu)}
      >
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
    </div>
  );
};

export default Menu;
