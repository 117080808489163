// Home.jsx
import React, { useEffect, useRef } from "react";
import "./Home.css";
import apple from "../assets/previewapple.png";
import salkhome from "../assets/salk-home.png";

const Home = ({ scrollToAbout }) => {
  const textRef = useRef(null);
  const imagesRef = useRef([]);

  useEffect(() => {
    const options = {
      rootMargin: "0px",
      threshold: 0.5, // Trigger when 50% of the text is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
        }
      });
    }, options);

    observer.observe(textRef.current);

    imagesRef.current.forEach((img) => {
      observer.observe(img);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="section" id="home">
      <div className="cta-home-image">
        <div className="call-to-action-title slide-up" ref={textRef}>
          <h2 className="title">
            Revolutionizing Campus Life with Tailored Mobile Solutions
          </h2>
          <p className="sub-title">
            Experience the Future of Campus Innovation with Zenith Technologies
            LLC
          </p>

          {/* Button for scrolling to About section */}
          <button className="cta-button" onClick={scrollToAbout}>
            Unlock Innovation
          </button>
        </div>
        <div className="cta-images">
          <img
            src={apple}
            className="preview-image slide-up"
            alt="apple-device-preview"
            ref={(el) => imagesRef.current.push(el)}
          />
          <img
            src={salkhome}
            className="preview-image slide-up"
            alt="google-device-preview"
            ref={(el) => imagesRef.current.push(el)}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
