import React from "react";
import "./DetailCards.css";
import detailCardsData from "../data/DetailCardsData.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDatabase,
  faGear,
  faCode,
  faArrowTrendUp,
} from "@fortawesome/free-solid-svg-icons";

const detailCardIcons = [faDatabase, faGear, faCode, faArrowTrendUp];

const DetailCards = () => {
  return (
    <div className="detail-card-list">
      {detailCardsData.map((card, index) => (
        <div className="detail-card" key={index}>
          <h3 className="detail-cards-title">{card.header}</h3>
          <div className="detail-cards-icon">
            <FontAwesomeIcon
              className="detail-cards-icon-img"
              icon={detailCardIcons[index % detailCardIcons.length]}
            />
          </div>
          <p className="detail-cards-info">{card.details}</p>
        </div>
      ))}
    </div>
  );
};

export default DetailCards;
